.produtos__interna{
	padding-bottom: 50px;
	overflow: hidden;

	.produto__card{
		margin-bottom: 30px;
	}
}
.produto__interna__controls{
	position: absolute;
	bottom: 20px;
	left: 20px;
	font-size: 25px;
	line-height: 25px;

	a{
		color: #fff;
		transition: all 0.3s linear;

		&:hover{
			color: #ed1c24;
		}
	}
}
.titulo__produto__interna{
	font-family: 'montserratmedium';
	font-size: 26px;
	line-height: 30px;
	font-weight: 1000;
	color: #ed1c24;
	margin-bottom: 15px;
}
.produto__interna__desc{
	text-align: justify;
	margin-bottom: 30px;
}
.produto__interna__btns{
	display: flex;
	justify-content: space-between;
}
.produto__interna__btn{
	width: 250px;
	max-width: 48%;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 60px;
	background-color: #ed1c24;
	color: #fff;
	font-size: 20px;
	line-height: 20px;
	font-family: 'montserratmedium';
	font-weight: 400;
	transition: all 0.3s linear;

	&:hover{
		text-decoration: none;
		background-color: darken(#ed1c24, 20%);
		color: #fff;
	}
}
