.politica__de__privacidade{
	padding-bottom: 104px;
	padding-top: 100px;

	.descricao{
		color: black;
		font-size: 13px;
		font-family: 'montserratregular',sans-serif;
		text-align: justify;
	}

	.page-header{
		.title-header{
			color: map-get($cores, cor-primaria);
			font-size: 30px;
			text-transform: uppercase;
			text-align: center;
			margin-bottom:30px;
		}
	}

	.btn__padrao{
		width: 220px;
		height: 55px;
		border-radius: 55px;
		margin-left: auto;
		margin-right: auto;
		background: map-get($cores, cor-primaria);
		color:  #fff;
		font-size: 14px;
		font-family: 'montserratbold',sans-serif;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 30px;
		transition: background .4s linear;
		box-shadow: none;

	}
}
