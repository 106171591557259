.banner__carousel{
	width: 100%;
	max-width: 1920px;
	margin-right: auto;
	margin-left: auto;
	overflow: hidden;
	position: relative;
}
.banner__inner{
	width: 160%;
	max-width: 1920px;
	left: 50%;
	transform: translateX(-50%);
	position: relative;
}
.banner__indicators{
	justify-content: flex-start;

	li{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		min-width: 40px;
		height: 40px;
		border-radius: 50%;
		margin: 0px 5px;
		background-color: transparent;
		opacity: 1;
		color: #fff;
		font-family: 'montserratmedium';
		font-style: normal;
		font-size: 18px;
		font-weight: 900;
		line-height: 18px;
		text-indent: 1px;
		padding-right: 2px;
		padding-top: 2px;
		text-shadow: 0px 0px 5px rgba(#000, 0.6);

		&.active{
			background-color: #ed1c24;
		}
	}
}
.produtos{
	padding: 40px 0px 50px 0px;
}
.titulo__secao{
	font-family: 'montserratblack_italic';
	font-size: 55px;
	line-height: 60px;
	color: #07344c;
}
.sub__secao{
	font-family: 'montserratbold_italic';
	color: #ed1c24;
	font-size: 20px;
	line-height: 24px;
	position: relative;
	display: flex;
	align-items: center;

	&:after{
		height: 5px;
		content: '';
		position: relative;
		display: inline-block;
		width: auto;
		flex-grow: 1;
		max-width: 100%;
		background-color: #ed1c24;
		margin-left: 10px;

	}
}
.produtos__header{
	margin-bottom: 20px;
}
.titulo__pesquisa{
	font-family: 'montserratbold_italic';
	color: #ed1c24;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	margin-bottom: 10px;
}
.segura__procurar{
	display: flex;
	max-width: 930px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding: 5px;
	border-radius: 36px;
	overflow: hidden;
	box-shadow: 0px 0px 5px rgba(#000, 0.5);
	margin-bottom: 40px;
}
.pesquisa__input{
	display: block;
	width: 100%;
	height: auto;
	padding: 15px 30px;
	font-family: 'montserratregular';
	font-size: 14px;
	line-height: 18px;
	border: none;

	&:focus{
		outline: none;
	}
}
.btn__pesquisa{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	min-width: 50px;
	border-radius: 50%;
	background-color: #ed1c24;
	color: #fff;
	border: none;
	font-size: 24px;
	line-height: 24px;
	transform: scaleX(-1);

	&:focus{
		outline: none;
	}
}
.produto__card{
	display: flex;
	align-items: center;

	&:hover{
		text-decoration: none;

		.produto__img{
			&:after{
				opacity: 1;
			}
		}
	}
}
.produto__txt{
	padding: 30px;
	background-color: #07344c;
	color: #fff;
}
.produto__titulo{
	font-family: 'montserratbold_italic';
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 15px;
}
.produto__img{
	display: block;
	box-shadow: 0px 0px 8px rgba(#000, 0.5);
	position: relative;

	&:after{
		content: '\f067';
		position: absolute;
		z-index: 10;
		width: 42px;
		height: 42px;
		border-radius: 50%;
		background-color: #ed1c24;
		color: #fff;
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
		display: flex;
		align-items: center;
		justify-content: center;
		bottom: 10px;
		right: 10px;
		box-shadow: 0px 0px 5px rgba(#000, 0.5);
		transition: all 0.3s linear;
		opacity: 0;
	}
}
.produto__owl.owl-carousel{
	padding: 0px 10px;

	.owl-item{
		padding: 10px;
	}
	.owl-nav{
		button.owl-prev,
		button.owl-next{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			font-size: 25px;
			line-height: 25px;
			color: #07344c;

			&:hover{
				text-decoration: none;
				color: #ed1c24;
			}

			&:focus{
				outline: none;
			}
		}
		button.owl-prev{
			left: 0px;
		}
		button.owl-next{
			right: 0px;
		}
	}
}
.btn__interna{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 260px;
	height: 60px;
	border-radius: 30px;
	background-color: #ed1c24;
	color: #fff;
	font-size: 20px;
	line-height: 24px;
	font-family: 'montserratmedium';
	font-weight: 600;
	transition: all 0.3s linear;

	&:hover{
		color: #fff;
		text-decoration: none;
		background-color: darken(#ed1c24, 15%);
	}
}
.produto__segura__btn{
	display: flex;
	justify-content: center;
}
.quem__somos{
	padding-bottom: 44px;
	overflow: hidden;
}
.quem__somos__header{
	margin-bottom: 20px;
}
.quem__desc{
	text-align: justify;
}
.quem__somos__card{
	display: flex;
	padding: 18px 20px 18px 15px;
	background-color: #07344c;
	color: #fff;
	align-items: flex-start;

	&:hover{
		text-decoration: none;
		color: #fff;
	}
}
.quem__somos__img{
	margin-right: 25px;
	margin-bottom: 50px;
	max-width: 85px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.quem__controls{
	position: absolute;
	bottom: 22px;
	left: 20px;
	width: 85px;
	display: flex;
	justify-content: center;
	font-size: 26px;
	line-height: 30px;

	a{
		display: block;
		color: #fff;
		transition: all 0.3s linear;
		margin: 0px 5px;

		&:hover{
			text-decoration: none;
			color: #ed1c24;
		}
	}
}
.quem__item__titulo{
	margin-bottom: 15px;
	font-family: 'montserratbold_italic';
	font-size: 20px;
	line-height: 24px;
}
.quem__item__desc{
	text-align: justify;
}
.servicos{
	padding: 30px 0px 50px 0px;
	overflow: hidden;

	.container{
		position: relative;

		&:before{
			z-index: -1;
			top: -30px;
			left: 15px;
			width: 100vw;
			background-color: #d1d1d1;
			height: 256px;
			content: '';
			position: absolute;
		}
	}
}
.servicos__header{
	height: 226px;
	margin-bottom: 25px;
}
.servicos__header__desc{
	--linhas: 5;
}
.servicos__titulos{
	margin-bottom: 10px;
}
.servico__titulo{
	.icon__mask{
		background-color: currentColor;
		margin-right: 10px;
		min-width: 41px;
	}
	font-family: 'montserratmedium';
	font-weight: 600;
	font-size: 26px;
	line-height: 30px;
	color: #07344c;
	display: flex;
	align-items: center;
	margin-bottom: 5px;
	transition: all 0.3s linear;
}
.servico__card{
	display: block;
	margin-bottom: 15px;

	&:hover{
		text-decoration: none;

		.servico__titulo{
			color: #ed1c24;
		}
	}
}
.servico__card__desc{
	text-align: justify;
}
.servicos__controls{
	font-size: 25px;
	line-height: 25px;
	display: flex;

	a{
		display: block;
		transition: all 0.3s linear;
		margin: 0px 5px;

		&:hover{
			text-decoration: none;
			color: #ed1c24;
		}
	}
}
.videos{
	position: relative;

	&:after{
		z-index: -1;
		width: 100%;
		height: 200px;
		bottom: 0px;
		left: 50%;
		transform: translateX(-50%);
		content: '';
		position: absolute;
		background-color: #07344c;
	}
	.container{
		box-shadow: 0px 0px 10px rgba(#000, 0.5);
		padding: 0px;
	}
	.row__video{
		display: flex;
		justify-content: space-between;
		padding: 0px;

		.col__videos__segura{
			background-color: #d1d1d1;
			padding: 30px 60px 40px 30px;
			display: flex;
			align-items: center;
			width: 46%;
		}
		.col__button__segura{
			padding: 0px;
			max-width: 100%;
			width: 54%;

			.video__thumb{
				padding: 0px;
				display: block;
				position: relative;
				border: none;
				max-width: 100%;
				width: 100%;
				height: 100%;
				background-image: var(--bg);
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;

				&:hover{
					&:after{
						opacity: 1;
					}
				}

				&:focus{
					outline: none;
				}

				&:after{
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					content: '';
					position: absolute;
					width: 108px;
					height: 108px;
					opacity: 0;
					background-image: url('../images/play__bg.png');
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
					transition: all 0.3s linear;
				}
			}
		}
	}
}
.modal.video__modal{
	.modal-dialog{
		max-width: 900px;
		width: 90%;

		.modal-content{
			background-color: transparent;
			border: none;
		}
		.modal-header{
			display: flex;
			justify-content: flex-end;
		}
		.btn__fechar{
			width: 40px;
			height: 40px;
			border-radius: 50%;
			border: none;
			background-color: #ed1c24;
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 24px;
			line-height: 24px;

			&:focus{
				outline: none;
			}
		}
		.modal-header{
			border: none;
			padding: 0px;
			padding-right: 15px;
		}
	}
}
.video__header{
	margin-bottom: 15px;
}
.videos__desc{
	text-align: justify;
	margin-bottom: 20px;
}
@include media-breakpoint-down (md) {
	.quem__desc{
		margin-bottom: 20px;
	}
	.servicos{
		position: relative;

		.servicos__header{
			position: relative;
			height: auto;
			padding-bottom: 30px;

			.sub__secao{
				max-height: 100% !important;
			}
			.servicos__header__desc{
				max-height: 100% !important;
			}

			&:before{
				z-index: -1;
				top: -30px;
				left: 50%;
				transform: translateX(-50%);
				width: calc(100vw + 100px);
				background-color: #d1d1d1;
				height: calc(100% + 30px);
				content: '';
				position: absolute;
			}
		}

		.container{
			&:before{
				display: none;
			}
		}
		.row{
			.col-lg-6{
				&:first-child{
					order: 2;
				}
				&:last-child{
					order: 1;
				}
			}
		}
	}
	.servicos__controls{
		justify-content: center;
		margin-bottom: 20px;
	}
	.servicos__img{
		text-align: center;
	}
	.videos{
		.row__video{
			flex-direction: column;

			.col__videos__segura{
				width: 100%;
				max-width: 100%;
			}
			.col__button__segura{
				width: 100%;
				max-width: 100%;
				height: 400px;
				max-height: 100%;

				.video__thumb{
					&:after{
						opacity: 1;
					}
				}
			}
		}
	}
}
@include media-breakpoint-up (lg) {

}
@include media-breakpoint-only (lg) {
	.produto__card{
		flex-direction: column;
		align-items: center;
		max-width: 350px;
		margin-right: auto;
		margin-left: auto;
		width: 100%;
	}
	.col__button__segura{
		display: flex;
		align-items: center;
	}
}
@include media-breakpoint-down (sm) {
	.produto__card{
		flex-direction: column;
		align-items: center;
		max-width: 350px;
		margin-right: auto;
		margin-left: auto;
		width: 100%;
	}
}
@include media-breakpoint-down (xs) {
	.titulo__secao{
		font-size: 34px;
		line-height: 38px;
	}
	.quem__somos__card{
		flex-direction: column;
		align-items: center;
		text-align: center;
		padding-bottom: 60px;

		.quem__somos__img{
			margin-bottom: 15px;
			margin-right: 0px;
		}
	}
	.quem__controls{
		width: 100%;
		left: 0px;
		bottom: 15px;
	}
}
