@media (min-width: 1200px){
    .msg__cookies {
        max-width: 1140px;
    }
}

@media (min-width: 768px){
    .msg__cookies {
        display: grid;
        grid-template-columns: 1fr -webkit-min-content;
        grid-template-columns: 1fr min-content;
        grid-column-gap: 10px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.content{

	a{
		margin-left: 5px;
	}
}

.msg__cookies {
    background: #fff;
    position: fixed;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    width: 100%;
    z-index: 999999999;
    border: 1px solid rgba(0,0,0,.2);
    font-family: "poppinsregular",sans-serif;
    animation: fadeIn 1s linear;
	font-size: 16px;
	flex-direction: row;
}

.msg__cookies:not(.msg__cookies--shown) {
    display: none;
}

.msg__cookies--shown{
	display: flex;
}

.msg__cookies--hide{
    animation: fadeOut 1s linear;
}

.msg__cookies a{
	color: red;
    font-family: 'poppinsregular',sans-serif;
    text-decoration: underline;
}

.msg__cookies .btn{
    box-shadow: none;
    background: red;
    color: #fff;
    font-size: 14px;
    font-family: 'poppinsbold',sans-serif;
    transition: background .4s linear;
    width: 154px;
    height:38px;
    display: flex;
    justify-content:center;
    align-items: center;
}

.msg__cookies .btn:hover{
    background: rgb(156, 8, 8);
}
