.manual__card{
	display: flex;
	align-items: center;
	margin-bottom: 30px;

	&:hover{
		text-decoration: none;

		.manual__conteudo{
			background-color: #07344c;
			color: #fff;

			.manual__titulo{
				color: #ed1c24;
			}
		}
	}
}
.manual__img{
	box-shadow: 0px 0px 5px rgba(#000, 0.4);
	z-index: 5;
}
.manual__conteudo{
	padding: 30px 30px 35px 30px;
	background-color: #d1d1d1;
	display: flex;
	transition: all 0.3s linear;
}
.manual__titulo{
	font-family: 'montserratbold_italic';
	font-size: 20px;
	line-height: 24px;
	color: #07344c;
	margin-bottom: 20px;
	transition: all 0.3s linear;
}
.manual__btn{
	width: 254px;
	height: 60px;
	border-radius: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ed1c24;
	color: #fff;
	font-size: 20px;
	line-height: 24px;
}
.segura__manual__btn{
	display: flex;
	align-items: center;
	margin-left: 15px;
}
.manual__txt__desc{
	text-align: justify;
}
.manual__interna{
	padding-bottom: 50px;
}
