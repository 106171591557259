.bg__menu{
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(#000, 0.7);
	z-index: 999;
	animation: fadeIn 0.4s linear;
	position: fixed;

	&.hide{
		animation: fadeOut 0.4s linear;
	}
}
.topo__sociais{
	display: flex;

	a{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 36px;
		height: 36px;
		border: 2px solid #fff;
		color: #fff;
		background-color: transparent;
		border-radius: 50%;
		margin: 0px 2px;
		transition: all 0.3s linear;

		&:hover{
			text-decoration: none;
			background-color: #ed1c24;
			border: 2px solid #ed1c24;
		}
	}
}
.main__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
}
.segura__orcamento__btn{
	display: flex;
	align-items: center;
}
.orcamento__btn{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 160px;
	height: 60px;
	background-color: #ed1c24;
	color: #fff;
	transition: all 0.3s linear;
	font-family: 'montserratmedium_italic';

	&:hover{
		background-color: #07344c;
		color: #fff;
		text-decoration: none;
	}
}
@include media-breakpoint-up (lg) {
	.topo__mbl{
		display: none;
	}
	.topo__cima,
	.topo__meio,
	.topo__baixo{
		.container{
			display: flex;
			justify-content: space-between;
		}
	}
	.topo__cima,
	.topo__baixo{
		color: #fff;
		background-color: #07344c;
	}
	.topo__cima{
		padding: 10px 0px;
	}
	.topo__contatos{
		display: flex;
		align-items: center;
	}
	.contato__box{
		display: flex;
		align-items: center;
		margin: 0px 10px;
		font-family: 'montserratmedium_italic';
		font-size: 12px;
		line-height: 16px;
	}
	.contato__box__icon{
		font-size: 20px;
		line-height: 20px;
		margin-right: 5px;
	}
	.topo__sociais{
		padding-left: 40px;
		margin-left: 40px;
		position: relative;

		&:before{
			width: 1px;
			height: 14px;
			left: 0px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			background-color: #fff;
			content: '';
		}
	}
	.bem__vindo{
		display: flex;
		align-items: center;
		font-family: 'montserratmedium_italic';
		font-size: 12px;
		line-height: 16px;
	}
	.main__logo{
		display: block;
		padding: 8px 0px;
		margin-right: 20px;
	}
	.main__menu{
		display: flex;
		justify-content: space-between;
		flex-grow: 1;
		margin-right: 30px;
	}
	.menu__item{
		display: flex;
	}
	.menu__item--active{
		.menu__link{
			font-family: 'montserratextrabold_italic';
		}
	}
	.menu__link{
		display: flex;
		align-items: center;
		font-family: 'montserratmedium_italic';
		font-size: 12px;
		line-height: 12px;
		color: #07344c;

		&:hover{
			font-family: 'montserratextrabold_italic';
			text-decoration: none;
			color: #07344c;
		}
	}
	.topo__meio{
		.container{
			border-bottom: 5px solid #ed1c24;
		}
	}
	.categorias__menu{
		list-style: none;
		padding-left: 0px;
		margin-bottom: 0px;
		display: flex;
		justify-content: space-between;
		font-family: 'montserratmedium_italic';

		.dropdown-menu{
			left: auto !important;
			right: 30px;
			transform: none !important;
			top: 60px !important;
			padding: 0px;
			font-size: 13px;
			line-height: 19px;
			font-family: 'montserratmedium';
			color: #07344c;
			border: none;
			border-radius: 0px;

			.conteudo__categorias{
				max-height: 200px;
				overflow-y: auto;
			}

			&:after{
				border: 15px solid transparent;
				border-bottom: 15px solid #fff;
				content: '';
				position: absolute;
				right: 20px;
				top: -29px;
			}

			a{
				display: block;
				min-width: 350px;
				padding: 15px 22px;
				border-bottom: 1px solid #ededed;
				position: relative;
				border-right: 1px solid #ededed;
				border-left: 1px solid #ededed;

				&:hover{
					text-decoration: none;
					background-color: #ededed;
					color: #ed1c24;

					&:after{
						opacity: 1;
					}
				}

				&:after{
					height: 100%;
					width: 7px;
					content: '';
					position: absolute;
					top: 0px;
					right: 0px;
					background-color: #ed1c24;
					transition: all 0.3s linear;
					opacity: 0;
				}
			}
		}

		.categorias__link{
			padding: 10px 10px;
			display: flex;
			align-items: center;
			min-height: 60px;
			border: none;
			background-color: transparent;
			color: #fff;
			vertical-align: middle;
			height: 100%;

			&:focus{
				outline: none;
			}
			&:hover{
				text-decoration: none;
			}
		}
	}
}
@include media-breakpoint-only (lg) {
	.categorias__link{
		padding: 0px 5px;
		text-align: center;
		font-size: 11px;
	}
	.main__logo{
		margin-right: 10px;
		max-width: 180px;
	}
	.main__menu{
		margin-right: 10px;
	}
	.orcamento__btn{
		width: 140px;
		height: 40px;
	}
}
@include media-breakpoint-down (md) {
	.topo__main{
		position: fixed;
		top: 0px;
		left: -250px;
		width: 250px;
		transition: all 0.3s linear;
		overflow-y: auto;
		height: 100%;
		background-color: #fff;
		z-index: 1000;
		display: flex;
		flex-direction: column;

		.topo__meio{
			order: 1;

			.container{
				padding: 0px;
			}
		}
		.topo__baixo{
			order: 2;

			.container{
				padding: 0px;
			}
		}
		.topo__cima{
			order: 3;
		}

		&.topo__main--shown{
			left: 0px;
		}
	}
	.topo__mbl{
		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 5px solid #ed1c24;
			padding: 10px 15px;
		}
	}
	.btn__mbl{
		font-size: 30px;
		line-height: 30px;
		padding: 0px;
		background-color: transparent;
		border: none;
		margin-left: 10px;

		&:focus{
			outline: none;
		}
	}
	.main__logo{
		display: block;
		padding: 10px;
	}
	.menu__link{
		display: block;
		padding: 5px 10px;
		margin-bottom: 5px;
		transition: all 0.3s linear;

		&:hover{
			background-color: #ed1c24;
			color: #fff;
			font-family: 'montserratextrabold_italic';
			text-decoration: none;
		}
	}
	.menu__item--active{
		.menu__link{
			background-color: #ed1c24;
			color: #fff;
			font-family: 'montserratextrabold_italic';
		}
	}
	.main__menu{
		margin-bottom: 10px;
	}
	.segura__orcamento__btn{
		display: flex;
		justify-content: center;
		margin-bottom: 15px;
	}
	.categorias__menu{
		padding-left: 0px;
		margin-bottom: 0px;
		list-style: none;
		margin-bottom: 20px;
	}
	.categorias__link{
		display: block;
		padding: 10px;
		background-color: #07344c;
		color: #fff;
		margin-right: 0px;
		border: none;
		text-align: left;
		width: 100%;

		&:hover{
			text-decoration: none;
			color: #fff;
		}

		&:focus{
			outline: none;
		}
	}
	.dropdown-menu{
		position: relative !important;
		width: 100%;
		transform: none !important;
		max-width: 100%;
		padding: 0px;
		border: none;
	}
	.conteudo__categorias{
		border-bottom: 2px solid #07344c;

		a{
			display: block;
			font-size: 13px;
			line-height: 17px;
			font-family: 'montserratmedium_italic';
			padding: 5px 10px;
			background-color: #ededed;

			&:hover{
				text-decoration: none;
				color: #000;
			}
		}
	}
	.bem__vindo{
		text-align: center;
		font-size: 15px;
		line-height: 15px;
		padding: 10px 0px;
	}
	.topo__contatos{
		.barrinha{
			display: none;
		}
	}
	.contato__box{
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 10px;
		text-align: center;

		.barrinha{
			display: none;
		}

		.contato__box__icon{
			font-size: 20px;
			line-height: 20px;
		}
	}
	.contato__box__tels{
		flex-direction: column;
		align-items: center;
	}
}
@include media-breakpoint-down (sm) {
	.mbl__logo{
		display: block;
		max-width: 200px;
		width: 100%;
	}
}
