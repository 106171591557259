.videos__interna{
	padding-bottom: 50px;
}
.video__thumb__interna{
	padding: 0px;
	border: none;
	background-color: transparent;
	margin-bottom: 30px;
	position: relative;

	.video__thumb__legenda{
		font-size: 18px;
		line-height: 22px;
		margin-bottom: 10px;
		padding-top: 10px;
	}
	.segura__thumb__imagem{
		position: relative;

		&:after{
			top: 50%;
			left: 50%;
			position: absolute;
			width: 50px;
			height: 50px;
			background-image: url('../images/play__bg2.png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			border-radius: 50%;
			z-index: 10;
			transform: translate(-50%, -50%);
			content: '';
			transition: all 0.3s linear;
		}
		&:before{
			top: 50%;
			left: 50%;
			position: absolute;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			box-shadow: inset 0px 0px 30px rgba(#000, 0.8);
			content: '';
			z-index: 9;
			transform: translate(-50%, -50%);
			opacity: 0;
			transition: all 0.3s linear;
		}
	}
	&:hover{
		.segura__thumb__imagem{
			&:before{
				opacity: 1;
			}
			&:after{
				box-shadow: 0px 0px 10px rgba(#000, 0.8);
			}
		}
	}
	&:focus{
		outline: none;
	}
}
