.contato__desc{
	text-align: justify;
	margin-bottom: 30px;
}
.contato__input{
	display: block;
	width: 100%;
	height: auto;
	padding: 16px 35px 15px 35px;
	font-size: 13px;
	line-height: 17px;
	border-radius: 24px;
	border: none;
	box-shadow: 0px 0px 5px rgba(#000, 0.4);

	&:focus{
		outline: none;
	}
}
.contato__btn{
	display: flex;
	justify-content: center;
	padding-top: 30px;

	.btn__interna{
		border: none;

		&:focus{
			outline: none;
		}
	}
}
.contato__interna{
	padding-bottom: 50px;
}
