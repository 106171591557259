.quem__somos__interna{
	overflow: hidden;
}
.quem__somos__bread{
	padding: 6px 0px;
	font-family: 'montserratbold_italic';
	color: #ed1c24;
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 50px;

	.container{
		display: flex;

		.segura__bread{
			display: flex;
			width: 100%;
			align-items: center;
			position: relative;

			&:after{
				height: 5px;
				content: '';
				position: relative;
				display: inline-block;
				width: auto;
				flex-grow: 1;
				max-width: 100%;
				background-color: #ed1c24;
				margin-left: 110px;
			}
		}
	}
}
.carousel__controls{
	position: absolute;
	bottom: 30px;
	left: 30px;
	font-size: 25px;
	line-height: 25px;
	display: flex;

	a{
		display: block;
		margin: 0px 5px;
		color: #fff;
		transition: all 0.3s linear;

		&:hover{
			color: #ed1c24;
		}
	}
}
.quem__somos__interna{
	padding-bottom: 50px;
}
@include media-breakpoint-down (md) {
	.quem__somos__bread{
		.container{
			.segura__bread{
				&:after{
					display: none;
				}
			}
		}
	}
	.quem__somos__interna{
		.container{
			.row{
				&:first-child{
					.col-lg-7{
						order: 2;
						margin-bottom: 15px;
						max-width: 636px;
						width: 100%;
						margin-right: auto;
						margin-left: auto;
					}
					.col-lg-5{
						order: 1;
						margin-bottom: 15px;
					}
				}
			}
		}
	}
}
