.btn__whatsapp{
	position: fixed;
    width: 60px;
    height: 60px;
    border-radius: 50%;;
    background-color: #25D366;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    line-height: 40px;
    z-index: 800;
	left: 15px;
	bottom: 15px;

	&:hover{
		text-decoration: none;
	}

	&:after{
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #25D366;
        animation: pulsar 2s linear infinite;
        z-index: -1;
    }

	&,
	.btn__whatsapp__wrapper{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 1.5em;//  40px * 1.5 = 60px
		height: 1.5em;// 40px * 1.5 = 60px
		border-radius: 50%
	}

	.btn__whatsapp__wrapper{
		overflow: hidden;
	}

	svg{
		// $dropShadow: drop-shadow(1px 1px #01883c);

		// @for $i from 2 through 60 {
		// 	$dropShadow: #{$dropShadow} drop-shadow(#{$i}px #{$i}px #01883c);
		// }

		width: 1em;
		height: 1em;
		fill: #FFF;
		// filter: $dropShadow;
	}
}
@keyframes pulsar{
    0%{
        opacity: 0.7;
        transform: scale(1);
    }
    100%{
        opacity: 0;
        transform: scale(2);
    }
}
.rodape__conteudo{
	padding: 40px 0px 30px 0px;
	border-bottom: 2px solid #fff;
}
.rodape{
	background-color: #07344c;
	color: #fff;
}
.rodape__desc{
	text-align: justify;
	margin-bottom: 10px;
}
.logo__rodape{
	margin-bottom: 10px;
}
.rodape__sociais{
	font-family: 'montserratbold_italic';
	font-size: 17px;
	line-height: 21px;
	display: flex;
	align-items: center;

	span{
		margin-right: 10px;
	}
}
.rodape__sociais__menu{
	display: flex;

	a{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		background-color: transparent;
		border: 2px solid #fff;
		color: #fff;
		border-radius: 50%;
		font-size: 18px;
		line-height: 18px;
		margin: 0px 5px;
		transition: all 0.3s linear;

		&:hover{
			background-color: #ed1c24;
			border: 2px solid #ed1c24;
			text-decoration: none;
		}

		span{
			margin-right: 0px;
		}
	}
}
.titulo__rodape{
	font-family: 'montserratbold_italic';
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 20px;
}
.rodape__menu{
	display: flex;
	flex-direction: column;
	font-family: 'montserratmedium_italic';
	font-size: 13px;
	line-height: 17px;

	a{
		display: block;
		padding: 2px 0px;
	}
}
.contato__rodape{
	display: flex;
	font-family: 'montserratmedium_italic';
	margin-bottom: 10px;

	.rodape__icone{
		margin-right: 5px;
		font-size: 16px;
		line-height: 20px;
	}
}
.rodape__news{
	text-align: justify;
	margin-bottom: 20px;
}
.news__form{
	background-color: #fff;
	border-radius: 30px;
	overflow: hidden;
	padding-bottom: 0px;

	.segura__procurar{
		margin-bottom: 0px;
	}
	.btn__pesquisa{
		transform: none;
	}
}
.rodape__creditos{
	padding: 15px 0px;
	font-size: 10px;
	text-align: center;
}
.gv8__box{
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover{
		text-decoration: none;
	}

	.icon{
		margin-left: 5px;
	}
}
@include media-breakpoint-down (md) {
	.rodape{
		text-align: center;
	}
	.rodape__sociais{
		flex-direction: column;
		align-items: center;

		span{
			margin-right: 0px;
		}
		.rodape__sociais__menu{
			padding-top: 10px;
			margin-bottom: 30px;
		}
	}
	.rodape__menu{
		margin-bottom: 30px;
	}
	.contato__rodape{
		flex-direction: column;
		align-items: center;
		margin-bottom: 10px;

		&:last-child{
			margin-bottom: 30px;
		}

		.rodape__icone{
			margin-right: 0px;
			margin-bottom: 5px;
		}
	}
	.rodape__news{
		text-align: center;
	}
	.titulo__rodape{
		margin-bottom: 10px;
	}
}
