.paginacao__custom{
	display: flex;
	justify-content: flex-end;

	.paginacao__item{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		color: #000;
		font-family: 'montserratbold_italic';
		transition: all 0.3s linear;

		&.paginacao__item--active{
			background-color: #ed1c24;
			color: #fff;
		}

		&:hover{
			background-color: #ed1c24;
			color: #fff;
			text-decoration: none;
		}
	}
}
.servico__interna__controls{
	position: absolute;
	bottom: 40px;
	left: 30px;
	font-size: 25px;
	line-height: 25px;
	color: #fff;
	display: flex;

	a{
		display: block;
		margin: 0px 5px;
		transition: all 0.3s linear;

		&:hover{
			color: #ed1c24;
		}
	}
}
.servicos__interna{
	padding-bottom: 50px;
	overflow: hidden;
}
.produtos__img__interna{
	margin-bottom: 30px;
}
.produtos__desc__interna{
	text-align: justify;
}
.row__produto__interna{
	margin-bottom: 50px;
}
@include media-breakpoint-down (md) {
	.servicos__interna__carousel{
		display: block;
		max-width: 543px;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 30px;
	}
}
