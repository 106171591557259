@font-face {
    font-family: 'montserratbold_italic';
    src: url('../fonts/montserrat-bold-italic/montserrat-bold-italic-webfont.eot');
    src: url('../fonts/montserrat-bold-italic/montserrat-bold-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-bold-italic/montserrat-bold-italic-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-bold-italic/montserrat-bold-italic-webfont.woff') format('woff'),
         url('../fonts/montserrat-bold-italic/montserrat-bold-italic-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-bold-italic/montserrat-bold-italic-webfont.svg#montserratbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratblack_italic';
    src: url('../fonts/montserrat-black-italic/montserrat-black-italic-webfont.eot');
    src: url('../fonts/montserrat-black-italic/montserrat-black-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-black-italic/montserrat-black-italic-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-black-italic/montserrat-black-italic-webfont.woff') format('woff'),
         url('../fonts/montserrat-black-italic/montserrat-black-italic-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-black-italic/montserrat-black-italic-webfont.svg#montserratblack_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 28, 2020 */



@font-face {
    font-family: 'montserratextrabold_italic';
    src: url('../fonts/montserrat-extrabold-italic/montserrat-extra-bold-italic-webfont.eot');
    src: url('../fonts/montserrat-extrabold-italic/montserrat-extra-bold-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-extrabold-italic/montserrat-extra-bold-italic-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-extrabold-italic/montserrat-extra-bold-italic-webfont.woff') format('woff'),
         url('../fonts/montserrat-extrabold-italic/montserrat-extra-bold-italic-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-extrabold-italic/montserrat-extra-bold-italic-webfont.svg#montserratextrabold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 20, 2020 */



@font-face {
    font-family: 'montserratmedium_italic';
    src: url('../fonts/montserrat-mediumitalic/montserrat-medium-italic-webfont.eot');
    src: url('../fonts/montserrat-mediumitalic/montserrat-medium-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-mediumitalic/montserrat-medium-italic-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-mediumitalic/montserrat-medium-italic-webfont.woff') format('woff'),
         url('../fonts/montserrat-mediumitalic/montserrat-medium-italic-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-mediumitalic/montserrat-medium-italic-webfont.svg#montserratmedium_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratmedium';
    src: url('../fonts/Montserrat_medium/montserrat-medium-webfont.eot');
    src: url('../fonts/Montserrat_medium/montserrat-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Montserrat_medium/montserrat-medium-webfont.woff2') format('woff2'),
         url('../fonts/Montserrat_medium/montserrat-medium-webfont.woff') format('woff'),
         url('../fonts/Montserrat_medium/montserrat-medium-webfont.ttf') format('truetype'),
         url('../fonts/Montserrat_medium/montserrat-medium-webfont.svg#montserratmedium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratregular';
    src: url('../fonts/montserrat_regular/montserrat-regular-webfont.eot');
    src: url('../fonts/montserrat_regular/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat_regular/montserrat-regular-webfont.woff2') format('woff2'),
         url('../fonts/montserrat_regular/montserrat-regular-webfont.woff') format('woff'),
         url('../fonts/montserrat_regular/montserrat-regular-webfont.ttf') format('truetype'),
         url('../fonts/montserrat_regular/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotoregular';
    font-display: optional;
    src: url('#{$fonts}/roboto_regular/roboto-regular-webfont.eot');
    src: url('#{$fonts}/roboto_regular/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.woff') format('woff'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
